import { render, staticRenderFns } from "./modifierGroup.vue?vue&type=template&id=e107ddc0&scoped=true"
import script from "./modifierGroup.vue?vue&type=script&lang=js"
export * from "./modifierGroup.vue?vue&type=script&lang=js"
import style0 from "./modifierGroup.vue?vue&type=style&index=0&id=e107ddc0&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e107ddc0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3084281448/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e107ddc0')) {
      api.createRecord('e107ddc0', component.options)
    } else {
      api.reload('e107ddc0', component.options)
    }
    module.hot.accept("./modifierGroup.vue?vue&type=template&id=e107ddc0&scoped=true", function () {
      api.rerender('e107ddc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/menu/editor/bulkUpdate/modifierGroup.vue"
export default component.exports