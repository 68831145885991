import { render, staticRenderFns } from "./menu-status-chip.vue?vue&type=template&id=64e15d69&scoped=true"
import script from "./menu-status-chip.vue?vue&type=script&lang=js"
export * from "./menu-status-chip.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e15d69",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3084281448/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64e15d69')) {
      api.createRecord('64e15d69', component.options)
    } else {
      api.reload('64e15d69', component.options)
    }
    module.hot.accept("./menu-status-chip.vue?vue&type=template&id=64e15d69&scoped=true", function () {
      api.rerender('64e15d69', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/menu-status-chip.vue"
export default component.exports